import PropTypes from 'prop-types';

import Alert from "@mui/material/Alert";
import InputAdornment from "@mui/material/InputAdornment";
import { Stack, Avatar, TextField, Autocomplete } from "@mui/material";

import { usePopover } from 'src/components/custom-popover';

import { useAuthContext } from '../../auth/hooks/index';

// ----------------------------------------------------------------------

export function WorkspacesPopover({ data = [], sx, ...other }) {
  const popover = usePopover();

  const { user, selectCompany } = useAuthContext();

  const handleChangeWorkspace = (option) => {
    selectCompany(option.id);
    popover.onClose();
  };

  return (<Stack direction="row">
    <Autocomplete
        value={user?.data?.current_company || ''}
        getOptionLabel={(option) => user?.data?.companies[option]?.name}
        options={data.map((o) => o.id)}
        disableClearable
        filterOptions={(options, params) => options.filter((o) => user?.data?.companies[o]?.name.toLowerCase().includes(params.inputValue.toLowerCase()) || user?.data?.companies[o]?.code.toLowerCase().includes(params.inputValue.toLowerCase()))}
        onChange={(event, newValue) =>
          handleChangeWorkspace(data.find((o) => o.id === newValue))
        }
        renderOption={(props, option) => {
          const company = user.data?.companies && Object.values(user.data.companies)?.find((o) => o.id === option);
          // const company = data.find((o) => o.id === option);
          return (
            <li {...props} key={company.name}>
              <Avatar alt={company.name}
                      src={company.logo_url}
                      sx={{ width: 24, height: 24, mr: 2, my: 0.5 }}/>
              {company.name}
            </li>
          );
        }}
        renderInput={(params) => {
          const company = user.data?.companies && Object.values(user.data.companies)?.find((o) => o.name === params.inputProps?.value);

          const baseField = {
            ...params,
            sx: {
              minWidth: 240,
            },
            inputProps: {
              ...params.inputProps,
              autoComplete: 'new-password',
              value: company?.name
            },
          };

          return (
            <TextField
              {...baseField}
              error={!user?.data?.current_company}
              InputProps={{
                ...params.InputProps,

                startAdornment: company ? (
                  <InputAdornment
                    position="start"
                  >

                    <Avatar alt={company?.name}
                            src={company?.logo_url}
                            sx={{ width: 24, height: 24 }}/>
                  </InputAdornment>
                ) : undefined,
              }}
            />
          );
        }}
    />
    {!user?.data?.current_company && <Alert severity="error" sx={{ ml: 2 }}>
      Seleziona l&apos;azienda per continuare
    </Alert>}
  </Stack>);
}

WorkspacesPopover.propTypes = {
  data: PropTypes.array,
  sx: PropTypes.object
};
