import PropTypes from 'prop-types';
import { Amplify } from 'aws-amplify';
import * as Sentry from "@sentry/react";
import { AMPLIFY_API } from 'src/config-global';
import { useMemo, useEffect, useReducer, useCallback } from 'react';
import { getStorage, setStorage, removeStorage } from '@heyjob-dash/shared/hooks/use-local-storage';
import {
  signIn,
  signUp,
  signOut,
  confirmSignIn,
  confirmSignUp,
  resetPassword,
  getCurrentUser,
  resendSignUpCode,
  fetchAuthSession,
  fetchMFAPreference,
  fetchUserAttributes, confirmResetPassword,
} from 'aws-amplify/auth';

import { AuthContext } from './auth-context';
import AuthService from '../../../services/AuthService';
import apiClient, { lambdaClient } from '../../../services/ApiService';

// ----------------------------------------------------------------------
/**
 * NOTE:
 * We only build demo at basic level.
 * Customer will need to do some extra handling yourself if you want to extend the logic and other features...
 */
// ----------------------------------------------------------------------

/**
 * DOCS: https://docs.amplify.aws/react/build-a-backend/auth/manage-user-session/
 */
Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: `${AMPLIFY_API.userPoolId}`,
      userPoolClientId: `${AMPLIFY_API.userPoolWebClientId}`,
    },
  },
});

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  if (action.type === 'COMPANY') {
    return {
      ...state,
      user: {
        ...state.user,
        data: {
          ...state.user.data,
          current_company: action.payload,
          selectedCompany: action.payload,
        }
      }
    };
  }
  return state;
};

const AUTH_STORAGE_KEY = 'company_settings';

// ----------------------------------------------------------------------

export function AuthProvider({ children }) {
  const [curState, dispatch] = useReducer(
    reducer,
    initialState,
    () => initialState
  );

  const initialize = useCallback(async () => {
    try {
      const { userId: currentUser } = await getCurrentUser();

      const userAttributes = await fetchUserAttributes();

      const { idToken, accessToken } = (await fetchAuthSession()).tokens ?? {};

      const groups = accessToken.payload['cognito:groups'];

      if (currentUser) {
        if (groups && groups.length > 0 && (groups.includes('Studio') || groups.includes('Operatore'))) {
          apiClient.interceptors.request.use(async (config) => {
            const curIdToken =
              (await fetchAuthSession()).tokens.idToken ?? idToken;
            config.headers.Authorization = `Bearer ${curIdToken}`;
            return config;
          });
          lambdaClient.interceptors.request.use(async (config) => {
            const curIdToken =
              (await fetchAuthSession()).tokens.idToken ?? idToken;
            config.headers.Authorization = `Bearer ${curIdToken}`;
            return config;
          });

          let userDataRes;
          try {
            userDataRes = await AuthService.getUserData();
          } catch (error) {
            await signOut();
            dispatch({
              type: 'INITIAL',
              payload: {
                user: null,
              },
            });
            return;
          }
          const state = getStorage(AUTH_STORAGE_KEY) || {};

          if (import.meta.env.VITE_ENV !== 'local') {
            // Set user in Sentry
            Sentry.setUser({
              id: userAttributes.sub,
              email: userAttributes.email,
            });
          }

          dispatch({
            type: 'INITIAL',
            payload: {
              user: {
                ...userAttributes,
                id: userAttributes.sub,
                displayName: `${userAttributes.given_name} ${userAttributes.family_name}`,
                idToken,
                accessToken,
                groups,
                // role: 'admin',
                data: {
                  ...userDataRes.data.user,
                  current_company: state?.company,
                  selectedCompany: state?.company,
                },
              },
            },
          });
        } else {
          await signOut();

          dispatch({
            type: 'INITIAL',
            payload: {
              user: null,
            },
          });
        }
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, [dispatch]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    const {
      isSignedIn,
      nextStep
    } = await signIn({
      username: email,
      password,
    });

    switch (nextStep.signInStep) {
      case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
        return nextStep.signInStep;
      case 'DONE':
      default:
        break;
    }

    if (isSignedIn) {
      const userAttributes = await fetchUserAttributes();

      const { idToken, accessToken } = (await fetchAuthSession()).tokens ?? {};

      const groups = accessToken.payload['cognito:groups'];

      if (groups && groups.length > 0 && (groups.includes('Studio') || groups.includes('Operatore'))) {
        apiClient.interceptors.request.use(async (config) => {
          const curIdToken = (await fetchAuthSession())?.tokens?.idToken ?? idToken;
          config.headers.Authorization = `Bearer ${curIdToken}`;
          return config;
        });
        lambdaClient.interceptors.request.use(async (config) => {
          const curIdToken = (await fetchAuthSession())?.tokens?.idToken ?? idToken;
          config.headers.Authorization = `Bearer ${curIdToken}`;
          return config;
        });

        const userDataRes = await AuthService.getUserData();

        if (import.meta.env.VITE_ENV !== 'local') {
          // Set user in Sentry
          Sentry.setUser({
            id: userAttributes.sub,
            email: userAttributes.email,
          });
        }

        dispatch({
          type: 'INITIAL',
          payload: {
            user: {
              ...userAttributes,
              id: userAttributes.sub,
              displayName: `${userAttributes.given_name} ${userAttributes.family_name}`,
              idToken,
              accessToken,
              groups,
              data: {
                ...userDataRes.data.user,
              },
            },
          },
        });
      } else {
        await signOut();

        throw new Error('Utente non autorizzato ad accedere al portale');
      }
    }

    return 'DONE';
  }, []);

  // FETCH USER DATA
  const fetchUserData = useCallback(async () => {
    const userDataRes = await AuthService.getUserData();
    dispatch({
      type: "REFRESH_USER_DATA",
      payload: {
        data: {
          ...curState.user.data,
          ...userDataRes.data.user,
        },
      },
    });
  }, [curState]);


  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    await signUp({
      username: email,
      password,
      options: {
        userAttributes: {
          email,
          given_name: firstName,
          family_name: lastName,
        },
      },
    });
  }, []);

  // CONFIRM REGISTER
  const confirmRegister = useCallback(async (email, code) => {
    await confirmSignUp({
      username: email,
      confirmationCode: code,
    });
  }, []);

  const confirmLogin = useCallback(async (newPassword) => {
    await confirmSignIn({
      challengeResponse: newPassword,
    });

    const userAttributes = await fetchUserAttributes();

    const { idToken, accessToken } = (await fetchAuthSession()).tokens ?? {};

    const groups = accessToken.payload['cognito:groups'];

    if (groups && groups.length > 0 && (groups.includes('Studio') || groups.includes('Operatore'))) {
      apiClient.interceptors.request.use(async (config) => {
        const curIdToken = (await fetchAuthSession()).tokens.idToken ?? idToken;
        config.headers.Authorization = `Bearer ${curIdToken}`;
        return config;
      });
      lambdaClient.interceptors.request.use(async (config) => {
        const curIdToken = (await fetchAuthSession()).tokens.idToken ?? idToken;
        config.headers.Authorization = `Bearer ${curIdToken}`;
        return config;
      });

      const userDataRes = await AuthService.getUserData();

      if (import.meta.env.VITE_ENV !== 'local') {
        // Set user in Sentry
        Sentry.setUser({
          id: userAttributes.sub,
          email: userAttributes.email,
        });
      }

      dispatch({
        type: 'INITIAL',
        payload: {
          user: {
            ...userAttributes,
            id: userAttributes.sub,
            displayName: `${userAttributes.given_name} ${userAttributes.family_name}`,
            idToken,
            accessToken,
            groups,
            data: {
              ...userDataRes.data.user,
            },
          },
        },
      });
    } else {
      await signOut();

      throw new Error('Utente non autorizzato ad accedere al portale');
    }
  }, []);

  // RESEND CODE REGISTER
  const resendCodeRegister = useCallback(async (email) => {
    await resendSignUpCode({
      username: email,
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    removeStorage(AUTH_STORAGE_KEY);
    await signOut();
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (email) => {
    await resetPassword({ username: email });
  }, []);

  // NEW PASSWORD
  const newPassword = useCallback(async (email, code, password) => {
    await confirmResetPassword({
      username: email,
      confirmationCode: code,
      newPassword: password,
    });
  }, []);

  const getAuthDetails = useCallback(async () => {
    const session = await fetchAuthSession();
    const mfaPreference = await fetchMFAPreference();
    return {
      ...session,
      mfa: mfaPreference,
    };
  }, []);

  const selectCompany = useCallback(async (companyId) => {
    // store in local storage
    const state = getStorage(AUTH_STORAGE_KEY) || {};
    state.company = companyId;
    setStorage(AUTH_STORAGE_KEY, state);

    dispatch({
      type: 'COMPANY',
      payload: companyId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const updateData = useCallback(async () => {
    const userDataRes = await AuthService.getUserData();

    dispatch({
      type: 'INITIAL',
      payload: {
        user: {
          ...curState.user,
          data: {
            ...userDataRes.data.user,
          },
        },
      },
    });

  }, [dispatch, curState.user]);

  // ----------------------------------------------------------------------

  const checkAuthenticated = curState.user ? 'authenticated' : 'unauthenticated';

  const status = curState.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: curState.user,
      method: 'amplify',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      initialize,
      login,
      logout,
      fetchUserData,
      register,
      newPassword,
      forgotPassword,
      confirmRegister,
      confirmLogin,
      resendCodeRegister,
      getAuthDetails,
      selectCompany,
      updateData,
    }),
    [
      status,
      curState.user,
      //
      initialize,
      login,
      logout,
      fetchUserData,
      register,
      newPassword,
      forgotPassword,
      confirmRegister,
      confirmLogin,
      resendCodeRegister,
      getAuthDetails,
      selectCompany,
      updateData,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
