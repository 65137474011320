import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { GuestGuard } from 'src/auth/guard';
import CompactLayout from 'src/layouts/compact';
import AuthClassicLayout from 'src/layouts/auth/classic';

import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// AMPLIFY
const AmplifyLoginPage = lazy(() => import('src/pages/auth/amplify/login'));
const AmplifyRegisterPage = lazy(() =>
  import('src/pages/auth/amplify/register')
);
const AmplifyVerifyPage = lazy(() => import('src/pages/auth/amplify/verify'));
const AmplifyNewPasswordPage = lazy(() =>
  import('src/pages/auth/amplify/new-password')
);
const AmplifyForgotPasswordPage = lazy(() =>
  import('src/pages/auth/amplify/forgot-password')
);
const AmplifyConfirmSigninPage = lazy(() => import('src/pages/auth/amplify/confirm-signin'));

// ----------------------------------------------------------------------

const authAmplify = {
  path: '',
  element: (
    <Suspense fallback={<SplashScreen />}>
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      path: 'login',
      element: (
        <GuestGuard>
          <AuthClassicLayout>
            <AmplifyLoginPage />
          </AuthClassicLayout>
        </GuestGuard>
      )
    },
    {
      path: 'register',
      element: (
        <GuestGuard>
          <AuthClassicLayout title="Manage the job more effectively with HeyJob">
            <AmplifyRegisterPage />
          </AuthClassicLayout>
        </GuestGuard>
      )
    },
    {
      element: (
        <CompactLayout>
          <Outlet />
        </CompactLayout>
      ),
      children: [
        { path: 'verify', element: <AmplifyVerifyPage /> },
        { path: 'new-password', element: <AmplifyNewPasswordPage /> },
        { path: 'forgot-password', element: <AmplifyForgotPasswordPage /> },
        { path: 'first-access', element: <AmplifyConfirmSigninPage /> },
      ]
    }
  ]
};

export const authRoutes = [
  {
    path: 'auth',
    children: [authAmplify]
  }
];
