import { m } from 'framer-motion';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import Badge, { badgeClasses } from '@mui/material/Badge';

import { varHover } from 'src/components/animate';
import { useSettingsContext } from 'src/components/settings';

import SvgColor from '../../components/svg-color/index';

// ----------------------------------------------------------------------

export default function DarkModeButton({ sx }) {
  const settings = useSettingsContext();

  return (
    <Badge
      color="error"
      variant="dot"
      // invisible={!settings.canReset}
      invisible
      sx={{
        [`& .${badgeClasses.badge}`]: {
          top: 8,
          right: 8,
        },
        ...sx,
      }}
    >
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        aria-label="settings"
        onClick={() => {
          settings.onUpdate(
            'themeMode',
            settings.themeMode === 'light' ? 'dark' : 'light'
          );
        }}
        sx={{
          width: 40,
          height: 40,
        }}
      >
        <SvgColor
          src={`/assets/icons/setting/ic_${
            settings.themeMode === 'light' ? 'moon' : 'sun'
          }.svg`}
        />
      </IconButton>
    </Badge>
  );
}

DarkModeButton.propTypes = {
  sx: PropTypes.object,
};
