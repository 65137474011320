import 'dayjs/locale/it';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import ReactDOM from 'react-dom/client';
import React, { Suspense } from 'react';
import * as Sentry from '@sentry/react';
import timezone from 'dayjs/plugin/timezone';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import { BrowserRouter } from 'react-router-dom';
import { replayIntegration } from '@sentry/react';
import { HelmetProvider } from 'react-helmet-async';
import updateLocale from 'dayjs/plugin/updateLocale';
import { browserTracingIntegration } from '@sentry/browser';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import './main.css';
import { App } from './app';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isLeapYear);
dayjs.extend(updateLocale);
dayjs.locale('it');
dayjs.tz.setDefault('Europe/Rome');

if (import.meta.env.VITE_ENV !== 'local') {
  Sentry.init({
    dsn: 'https://977a248eeaedeab698f1311ae9d26481@o4505538662694912.ingest.sentry.io/4506252333547520',
    environment: import.meta.env.VITE_ENV,
    integrations: [
      browserTracingIntegration({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/api-dev\.heyjob\.peopleincloud\.it/,
          /^https:\/\/api\.heyjob\.peopleincloud\.it/
          // /^https:\/\/u8nr6rutq9\.execute-api\.eu-south-1\.amazonaws\.com\/prod/
        ]
      }),
      replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <Suspense>
          <App />
        </Suspense>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);
