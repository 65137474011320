import 'src/locales/i18n';
import { AuthProvider } from 'src/auth/context/amplify';

import Router from 'src/routes/sections';

import ProgressBar from 'src/components/progress-bar';

import ThemeProvider from './theme/index';
import { LocalizationProvider } from './locales/index';
import { useScrollToTop } from './hooks/use-scroll-to-top';
import { MotionLazy } from './components/animate/motion-lazy';
import { SettingsProvider } from './components/settings/index';
import SnackbarProvider from './components/snackbar/snackbar-provider';

export const App = () => {
  useScrollToTop();

  return (
    <AuthProvider>
      <LocalizationProvider>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'default', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false
          }}
        >
          <ThemeProvider>
            <MotionLazy>
              <SnackbarProvider>
                <ProgressBar />
                <Router />
              </SnackbarProvider>
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </AuthProvider>
  );
};

// export const router = createBrowserRouter(
//     createRoutesFromElements(
//         <Route
//             element={<AuthLayout />}
//             loader={() => defer({ userPromise: getUserData() })}
//         >
//             <Route path="/login" element={<LoginPage />} />
//             <Route path="/new-password" element={<NewPasswordPage />} />
//             <Route path="/forgot-password" element={<ForgotPasswordPage />} />
//             <Route path="/reset-password" element={<ResetPasswordPage />} />
//
//             <Route path="/" element={<ProtectedLayout />}>
//                 <Route path="" element={<HomePage />} />
//                 <Route path="/anagrafiche" element={<AnagrafichePage />} />
//                 <Route path="/anagrafiche/:employee_id" element={<AnagraficaPage />} />
//                 <Route path="/timesheet" element={<TimesheetPage />} />
//                 <Route path="/timesheet/:year/:month" element={<TimesheetPage />} />
//                 <Route path="/timesheet/:year/:month/:employee_id" element={<MonthlyTimesheetPage />} />
//                 <Route path="/note-spese" element={<ExpenseReports />} />
//                 <Route path="/statistiche" element={<StatistichePage />} />
//                 <Route path="/comunicazioni" element={<ComunicazioniPage />} />
//                 <Route path="/help-desk" element={<HelpDeskPage />} />
//                 <Route path="/company" element={<AccountPage />} />
//                 <Route path="/config" element={<ConfigPage/>} />
//                 <Route path="/scadenziario" element={<ScadenziarioPage/>} />
//                 <Route path="/form-richieste" element={<FormRichiestePage/>} />
//                 <Route path="/servizi" element={<ServiziPage/>} />
//                 <Route path="/upload-documenti" element={<UploadDocumentiPage />} />
//                 <Route path="/import" element={<ImportPage />} />
//                 <Route path="/aziende" element={<CompaniesPage />} />
//                 <Route path="/aziende/:company_id" element={<CompanyPage />} />
//                 <Route path="/config/time-profiles/:time_profile_id" element={<TimeProfileDetail />} />
//                 <Route path="/config/timesheet-categories/:short_code" element={<TimesheetCategoryDetail />} />
//                 <Route path="/circolari" element={<LettersPage />} />
//                 <Route path="/news" element={<NewsPage />} />
//                 <Route path="/avvisi-popup" element={<PopupNoticePage />} />
//                 <Route path="/faq-e-learning" element={<FAQLearningPage />} />
//             </Route>
//         </Route>
//     )
// );
