import { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Grid,
  Button,
  Dialog,
  Select,
  MenuItem,
  InputLabel,
  DialogTitle,
  FormControl,
  DialogActions,
  DialogContent,
} from '@mui/material';

import { useAuthContext } from '../auth/hooks/index';

export default function SelectCompanyDialog({ open, onClose }) {
  const { user, selectCompany } = useAuthContext();
  const [companyId, setCompanyId] = useState('');

  const changeCompany = () => {
    selectCompany(companyId);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle fontWeight={700}>Seleziona Azienda</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="company-label">Azienda</InputLabel>
              <Select
                fullWidth
                labelId="company-label"
                variant="standard"
                onChange={(e) => setCompanyId(e.target.value)}
                value={companyId}
              >
                {user.data &&
                  user.data.companies &&
                  Object.values(user.data.companies).map((company) => (
                    <MenuItem value={company.id} key={company.id}>
                      {company.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={changeCompany}
          disabled={companyId === '' || companyId == null}
        >
          Conferma
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SelectCompanyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
