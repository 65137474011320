// ----------------------------------------------------------------------

export function skeleton(theme) {
  return {
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.neutral
        },
        rounded: {
          borderRadius: theme.shape.borderRadius * 2
        }
      }
    }
  };
}
