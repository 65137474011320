import { useMemo } from 'react';
import { useTranslate } from 'src/locales';
import { hasRole } from "@heyjob-dash/shared/utils/check-permission";

import { paths } from 'src/routes/paths';

import SvgColor from 'src/components/svg-color';

import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  timesheet: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData(user) {
  // const { user } = useAuthContext();

  const { t } = useTranslate();

  const data = useMemo(
    () => [
        // OVERVIEW
        // ----------------------------------------------------------------------
        {
          items: [
            {
              title: t('back'),
              path: paths.dashboard.root,
              icon: <Iconify sx={{ width: 24, height: 24 }} icon="solar:round-alt-arrow-left-bold-duotone" />
            }
          ]
        },
        {
          items: [
            {
              title: t('search company'),
              path: paths.dashboard.root,
              icon: ICONS.dashboard
            }
          ]
        },
        // SUPERADMIN
        // ----------------------------------------------------------------------
        {
          subheader: t('superadmin'),
          items: [
            {
              title: t('news'),
              path: paths.dashboard.news,
              icon: ICONS.label
            },
            {
              title: t('popup_notices'),
              path: paths.dashboard.popupNotices,
              icon: ICONS.external
            },
            {
              title: t('faq_e_learning'),
              path: paths.dashboard.faqElearning,
              icon: <Iconify sx={{ width: 24, height: 24 }} icon="solar:diploma-verified-bold-duotone" />
            },
            {
              title: t('external_services'),
              path: '#',
              icon: <Iconify sx={{ width: 24, height: 24 }} icon="solar:shield-warning-bold-duotone" />,
              children: [
                {
                  title: t('companies'),
                  path: paths.dashboard.externalLinks
                },
                {
                  title: t('employees'),
                  path: paths.dashboard.employeeExternalLinks
                }
              ]
            },
            {
              title: t('operatori'),
              path: paths.dashboard.consultants,
              icon: <Iconify sx={{ width: 24, height: 24 }} icon="solar:user-id-bold-duotone" />
            },
            {
              title: t('managers'),
              path: paths.dashboard.managers,
              icon: <Iconify sx={{ width: 24, height: 24 }} icon="solar:case-bold-duotone" />
            },
            {
              title: t('users'),
              path: paths.dashboard.users,
              icon: <Iconify sx={{ width: 24, height: 24 }} icon="solar:users-group-rounded-bold-duotone" />
            },
            {
              title: t('log email'),
              path: paths.dashboard.logEmail,
              icon: <Iconify sx={{ width: 24, height: 24 }} icon="solar:mailbox-bold-duotone" />
            }
          ]
        },
        // OPERATORE
        // ----------------------------------------------------------------------
        {
          subheader: t('operatore'),
          items: [
            {
              title: t('companies'),
              path: paths.dashboard.companies,
              icon: <Iconify sx={{ width: 24, height: 24 }} icon="solar:buildings-bold-duotone" />
            },
            {
              title: t('hiring'),
              path: paths.dashboard.hiring,
              icon: <Iconify sx={{ width: 24, height: 24 }} icon="solar:notification-unread-lines-bold-duotone" />
            },
            {
              title: t('terminations'),
              path: paths.dashboard.terminations,
              icon: <Iconify sx={{ width: 24, height: 24 }} icon="solar:notification-lines-remove-bold-duotone" />
            },
            {
              title: t('help_desk'),
              path: paths.dashboard.helpDesk,
              icon: ICONS.chat
            },
            {
              title: t('upload_documents'),
              path: paths.dashboard.uploadDocuments,
              icon: <Iconify sx={{ width: 24, height: 24 }} icon="solar:add-folder-bold-duotone" />
            },
            ...(hasRole(user, 'Studio') ? [{
              title: t('import'),
              path: paths.dashboard.import,
              icon: <Iconify sx={{ width: 24, height: 24 }} icon="solar:import-bold-duotone" />
            }] : []),
            {
              title: t('config'),
              path: paths.dashboard.config,
              icon: <Iconify sx={{ width: 24, height: 24 }} icon="solar:settings-bold-duotone" />
            },
            {
              title: t('letters'),
              path: paths.dashboard.letters,
              icon: ICONS.mail
            }
          ]
        },
        // COMPANY
        // ----------------------------------------------------------------------
        {
          subheader: t('company'),
          items: [
            // ------ AZIENDA ------
            {
              title: t('employees'),
              path: paths.company.employees,
              icon: ICONS.user,
              children: []
            },
            {
              title: t('timesheet'),
              path: paths.company.timesheet,
              icon: ICONS.timesheet,
              children: []
            },
            {
              title: t('documents'),
              path: paths.company.documents,
              icon: ICONS.file
            },
            {
              title: t('timetable'),
              path: paths.company.calendar,
              icon: ICONS.calendar
            },
            ...(user?.data?.current_company && user?.data?.companies[user?.data?.current_company]?.feature_expense_reports ? [{
              title: t('expense_reports'),
              path: paths.company.expenseReports,
              icon: ICONS.invoice
            }] : []),
            {
              title: t('dati contabili'),
              path: paths.company.payrollData,
              icon: <Iconify sx={{ width: 24, height: 24 }} icon="solar:chart-bold-duotone" />
            },
            {
              title: t('statistiche'),
              path: paths.company.stats,
              icon: ICONS.analytics,
            },
            {
              title: t('account'),
              path: paths.company.account,
              icon: ICONS.job
            },
            {
              title: t('config'),
              path: paths.company.config.root,
              icon: <Iconify sx={{ width: 24, height: 24 }} icon="solar:settings-bold-duotone" />,
              children: [
                {
                  title: t('timesheet'),
                  path: paths.company.config.timesheet,
                },
                {
                  title: t('clocking places'),
                  path: paths.company.config.clockingPlaces,
                },
                {
                  title: t('expense_reports'),
                  path: paths.company.config.expenseReports,
                },
                {
                  title: t('supervisors'),
                  path: paths.company.config.supervisors,
                },
              ],
            },
          ]
        }
      ],
    [t, user]
  );

  return data;
}
